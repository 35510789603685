import { render, staticRenderFns } from "./index.vue?vue&type=template&id=742aa19b&scoped=true&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"
import style0 from "./style.scss?vue&type=style&index=0&id=742aa19b&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "742aa19b",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/lib/docker/volumes/gitlab-runner-builds/_data/0/ochkarik/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('742aa19b')) {
      api.createRecord('742aa19b', component.options)
    } else {
      api.reload('742aa19b', component.options)
    }
    module.hot.accept("./index.vue?vue&type=template&id=742aa19b&scoped=true&", function () {
      api.rerender('742aa19b', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "frontend-new/apps/header/components/DoctorAppointment/index.vue"
export default component.exports